:root {
  --plyr-color-main: #0074bd;
}
.plyr--video .plyr__control--overlaid {
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--plyr-color-main);
  padding: 18px 20px;
}
.plyr--video .plyr__control--overlaid svg {
  height: 24px;
  width: 20px;
}
.plyr--video .plyr__control--overlaid:focus,
.plyr--video .plyr__control--overlaid:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--plyr-color-main);
}
.ce-gallery figure img.video-handler-item-image,
.video-handler-item-image {
  display: none;
}
.js-video-handler-play-button {
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.js-video-handler-play-button:before {
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent #0074bd;
  content: '';
  display: block;
  left: 50%;
  margin: 0 0 0 3px;
  position: absolute;
  top: 50%;
  width: 0;
  z-index: 2;
}
.js-video-handler-play-button:after {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: inherit;
  -moz-transition: inherit;
  -ms-transition: inherit;
  -webkit-transition: inherit;
  background: rgba(255, 255, 255, 0.7);
  content: '';
  display: block;
  height: 60px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 1;
}
.js-video-handler-play-button:focus:after,
.js-video-handler-play-button:hover:after {
  background-color: rgba(255, 255, 255, 0.9);
}
.js-video-started .js-video-handler-play-button {
  opacity: 0;
}
.js-loading .js-video-handler-play-button:before {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  animation: rotate 1.2s linear infinite;
  border: 4px solid;
  border-color: #0074bd transparent;
  content: '';
  display: block;
  height: 30px;
  margin: 0;
  width: 30px;
}
@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
    -moz-transform: translate(-50%, -50%) rotate(0);
    -ms-transform: translate(-50%, -50%) rotate(0);
    -webkit-transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@media screen and (max-width: 767px) {
  .ce-gallery figure img.video-handler-item-image,
  .video-handler-item-image {
    display: block;
  }
  .ce-gallery figure img.video-handler-item-image ~ .plyr,
  .ce-gallery figure img.video-handler-item-image ~ .video-embed-container-still-image,
  .video-handler-item-image ~ .plyr,
  .video-handler-item-image ~ .video-embed-container-still-image {
    display: none;
  }
}
